import PropTypes from "prop-types"
import React from "react"

import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import { Container } from "react-bootstrap"
import CustomNavbar from "./customNavBar"

const Header = ({ pageInfo }) => (
  <StaticQuery
    query={graphql`{
  logo: file(relativePath: {eq: "logo.png"}) {
    childImageSharp {
      gatsbyImageData(
        width: 160
        breakpoints: [35, 50, 90, 120, 160]
        layout: CONSTRAINED
      )
    }
  }
}
`}

    render={data => (
      <header>
        <Container className="align-middle">
          <a href="/">
            <GatsbyImage
              image={data.logo.childImageSharp.gatsbyImageData}
              div className="logo"
              alt=" Wondere Wereld van Tekeningen - Logo" />
          </a>
        </Container>
        <Container>
          <CustomNavbar pageInfo={pageInfo}/>
          <div className="section-heading">
            <h1 className="title">{pageInfo.pageTitle}</h1>
          </div>
        </Container>
      </header>
    )}
  />
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Wondere wereld van tekeningen`,
}

export default Header
