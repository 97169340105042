/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            image,
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaTitle = title || site.siteMetadata.title
  const metaImage = `${site.siteMetadata.siteUrl}${site.siteMetadata.image}`
  const keywords = site.siteMetadata.keywords || []

  return (
    <Helmet
      htmlAttributes={{ lang, }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        { name: `description`, content: metaDescription, },
        { name: `keywords`, content: keywords.join(","), },
        { property: `og:title`, content: metaTitle, },
        { property: `og:description`, content: metaDescription, },
        { property: `og:type`, content: `website`, },
        { property: `og:url`, content: metaImage, },
        { name: `twitter:creator`, content: site.siteMetadata.author, },
        { name: `twitter:title`, content: title, },
        { name: `twitter:description`, content: metaDescription, },
      ].concat(
        metaImage
          ? [
            {
              property: "og:image",
              content: metaImage,
            },
            {
              property: "og:image:width",
              content: metaImage.width,
            },
            {
              property: "og:image:height",
              content: metaImage.height,
            },
            {
              name: "twitter:card",
              content: "summary_large_image",
            },
          ]
          : [
            {
              name: "twitter:card",
              content: "summary",
            },
          ]
      ) .concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `nl`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
