import React from "react"
import { Nav, Navbar } from "react-bootstrap"

const CustomNavbar = ({ pageInfo }) => {
  return (
    <>
      <Navbar collapseOnSelect expand="md" variant="light" id="site-navbar">
        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-center">
          <Nav activeKey={pageInfo && pageInfo.pageName}>
            <Nav.Item> <Nav.Link eventKey="index" href="/"> Home </Nav.Link> </Nav.Item>
            <Nav.Item> <Nav.Link eventKey="hoe-werkt-het" href="/hoe-werkt-het"> Hoe werkt het? </Nav.Link> </Nav.Item>
            <Nav.Item> <Nav.Link eventKey="trainingen" href="/trainingen"> Trainingen, workshops en lezingen </Nav.Link>
            </Nav.Item>
            <Nav.Item> <Nav.Link eventKey="over-mij" href="/over-mij"> Over mij </Nav.Link> </Nav.Item>
            <Nav.Item> <Nav.Link eventKey="tarieven" href="/tarieven"> Tarieven </Nav.Link> </Nav.Item>
            <Nav.Item> <Nav.Link eventKey="kindercoaching" href="/kindercoaching"> Alles Kids Kindercoaching </Nav.Link>
            </Nav.Item>
            <Nav.Item> <Nav.Link eventKey="contact" href="/contact"> Contact </Nav.Link> </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

export default CustomNavbar
