/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children, pageInfo }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Container fluid className="px-0 main">
          <Row noGutters className="justify-content-center">
            <Col>
              <Header siteTitle={data.site.siteMetadata.title} pageInfo={pageInfo}/>
            </Col>
          </Row>
          <Row noGutters>
            <Col xl={{ span: 12, offset: 0 }} md={{ span: 10, offset: 1 }}>
              <Container className="mt-4">
                <main>{children}</main>
              </Container>
            </Col>
          </Row>
        </Container>
        <Container fluid className="px-0">
          <Row noGutters>
            <Col className="footer-col">
              <Footer/>
            </Col>
          </Row>
        </Container>
      </>
    )}
  />
)

export default Layout
