import { Link } from "gatsby"
import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import { FaFacebookF } from "react-icons/fa"

const Footer = () => (
  <footer id="footer">
    <Container>
      <main>
        <Container>
          <Container>
            <Row>
              <Col xs={{ span: 9 }} md={{ span: 10 }}>
                <p className="copyright">© Wondere Wereld van Tekeningen 2021<br/>
                  | <Link to="/cookies">Cookies</Link> | <Link to="/privacy">Privacy</Link> | <Link
                    to="/algemene-voorwaarden">Algemene Voorwaarden</Link>
                </p>
              </Col>
              <Col xs={{ span: 3 }} md={{ span: 2 }}>
                <div className="social">
                  <li>
                    <a href="https://www.facebook.com/wonderewereldvantekeningen/"
                       aria-label="Vind ons op Facebook"
                       className="facebook">
                      <FaFacebookF/>
                    </a>
                  </li>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </main>
    </Container>
  </footer>
)

export default Footer
